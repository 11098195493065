$(function() {

  var navMenuEl = document.getElementById('#nav-dropdown');
  // var videoLightboxEl = document.getElementById('#video-lightbox');

  $('.page-overlay').click(function(){
    if ($('body').hasClass('nav-open')) {
      $('body').removeClass('nav-open')
      bodyScrollLock.enableBodyScroll(navMenuEl);
    // } else if ($('body').hasClass('video-lightbox-show')) {
    //   $('body').removeClass('video-lightbox-show')
    //   bodyScrollLock.enableBodyScroll(videoLightboxEl);
    }
  });

  $('.nav-toggle').click(function(){
    if ($('body').hasClass('nav-open')) {
      $('body').removeClass('nav-open');
      bodyScrollLock.enableBodyScroll(navMenuEl);
    } else {
      // window.scrollTo(0, 0);
      // if ($('body').hasClass('book-a-tour-open')) {
      //   $('body').removeClass('book-a-tour-open');
      // }
      $('body').addClass('nav-open');
      bodyScrollLock.disableBodyScroll(navMenuEl);
    }
  });

  // $('#nav-menu .menu > ul > .menu-item.parent > a').click(function(e){
  //   e.preventDefault();
  //
  //   var $parent = $(this).parent();
  //   if ($parent.hasClass('open')) {
  //     $parent.removeClass('open');
  //   } else {
  //     $parent.addClass('open');
  //   }
  // });

  // $('.nav-search-toggle').click(function(){
  //   if ($('body').hasClass('nav-open')) {
  //     $('body').removeClass('nav-open');
  //     bodyScrollLock.enableBodyScroll(navMenuEl);
  //   } else {
  //     // window.scrollTo(0, 0);
  //     if ($('body').hasClass('book-a-tour-open')) {
  //       $('body').removeClass('book-a-tour-open');
  //     }
  //     $('body').addClass('nav-open');
  //     $('#search').focus();
  //     bodyScrollLock.disableBodyScroll(navMenuEl);
  //   }
  // });

  // $('.book-a-tour,#book-a-tour-form-close').click(function(e){
  //   e.preventDefault();
  //   if ($('body').hasClass('book-a-tour-open')) {
  //     $('body').removeClass('book-a-tour-open');
  //     bodyScrollLock.enableBodyScroll(bookATourFormEl);
  //   } else {
  //     if ($('body').hasClass('nav-open')) {
  //       $('body').removeClass('nav-open');
  //     }
  //     $('body').addClass('book-a-tour-open');
  //     bodyScrollLock.disableBodyScroll(bookATourFormEl);
  //   }
  // });

  $('.header-slider').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    // arrows: false,
    // dots: false,
    speed: 400,
    swipeToSlide: true,
    prevArrow: '<button type="button" class="slick-prev icon-left-open-big">Previous</button>',
    nextArrow: '<button type="button" class="slick-next icon-right-open-big">Next</button>',
  });

  $('.header-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    if ($('#header-slider-index').data('length') < 10) {
      $('#header-slider-index').html('0' + (nextSlide + 1));
    } else {
      $('#header-slider-index').html(nextSlide + 1);
    }
    // console.log(nextSlide + ':' + (nextSlide + 1));
  });

  $('.carousel-slides').slick({
    // autoplay: true,
    // autoplaySpeed: 5000,
    // arrows: false,
    // dots: false,
    speed: 400,
    swipeToSlide: true,
    slidesToShow: 2,
    // appendDots: $('.gallery-container'),
    prevArrow: '<button type="button" class="slick-prev icon-left-open-big">Previous</button>',
    nextArrow: '<button type="button" class="slick-next icon-right-open-big">Next</button>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.carousel-slides').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    if ($('#carousel-index').data('length') < 10) {
      $('#carousel-index').html('0' + (nextSlide + 1));
    } else {
      $('#carousel-index').html(nextSlide + 1);
    }
    // console.log(nextSlide + ':' + (nextSlide + 1));
  });

  $('.team-slides').slick({
    // autoplay: true,
    // autoplaySpeed: 5000,
    // arrows: false,
    // dots: false,
    speed: 400,
    swipeToSlide: true,
    slidesToShow: 3,
    // appendDots: $('.gallery-container'),
    prevArrow: '<button type="button" class="slick-prev icon-left-open-big">Previous</button>',
    nextArrow: '<button type="button" class="slick-next icon-right-open-big">Next</button>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.partner-slides').slick({
    // autoplay: true,
    // autoplaySpeed: 5000,
    // arrows: false,
    // dots: false,
    speed: 400,
    swipeToSlide: true,
    slidesToShow: 5,
    // appendDots: $('.gallery-container'),
    prevArrow: '<button type="button" class="slick-prev icon-left-open-big">Previous</button>',
    nextArrow: '<button type="button" class="slick-next icon-right-open-big">Next</button>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.gallery-images').slick({
    // autoplay: true,
    // autoplaySpeed: 5000,
    // arrows: false,
    // dots: false,
    speed: 400,
    swipeToSlide: true,
    appendDots: $('.gallery-container'),
    prevArrow: '<button type="button" class="slick-prev icon-linkarrow">Previous</button>',
    nextArrow: '<button type="button" class="slick-next icon-linkarrow">Next</button>'
  });

  $('.gallery-images').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    if ($('#gallery-images-index').data('length') < 10) {
      $('#gallery-images-index').html('0' + (nextSlide + 1));
    } else {
      $('#gallery-images-index').html(nextSlide + 1);
    }
    // console.log(nextSlide + ':' + (nextSlide + 1));
  });

  // $('.accordian .question').click(function(e){
  //   e.preventDefault();
  //
  //   var $faq = $(this).parent();
  //   if ($faq.hasClass('open')) {
  //     $faq.removeClass('open');
  //   } else {
  //     $('.accordian .faq').removeClass('open');
  //     $faq.addClass('open');
  //   }
  // });

  // $('#play-video-lightbox').click(function(){
  //   if ($('body').hasClass('video-lightbox-show')) {
  //     $('body').removeClass('video-lightbox-show')
  //     bodyScrollLock.enableBodyScroll(videoLightboxEl);
  //   } else {
  //     // window.scrollTo(0, 0);
  //     $('body').addClass('video-lightbox-show')
  //     bodyScrollLock.disableBodyScroll(videoLightboxEl);
  //   }
  // });

  $('input[name=Own_Device]').change(function(){
    if (this.value == 'No') {
      $('.asset-tag').addClass('show');
    } else {
      $('.asset-tag').removeClass('show');
    }
  });

  $('#show-more').click(function(){

    var $this = $(this);
    var page = $this.data('page');

    $.get($this.data('url') + '/p' + page, function(data) {
      $('#listing').append(data);
      page = page + 1;
      if ($this.data('max') == page) {
        $this.parents('.show-more-wrapper').addClass('hide');
      } else {
        $this.data('page', page);
      }

    }).fail(function() {
      alert('error');
    });
  });

});
